







import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import { Pages } from '@/views/Page.vue'
import Hero from '@/components/Hero.vue'

/* eslint-disable camelcase */
const subpages: Pages = {}
/* eslint-enable camelcase */

export default defineComponent({
  name: 'content-page',
  components: {
    Hero,
  },
  setup() {
    const { content } = useGetters(['content'])
    const afterHero = ref(false)

    onMounted(() => {
      setTimeout(() => {
        afterHero.value = true
      }, 1000)
    })

    return {
      afterHero,
      content,
      subpages,
    }
  },
})
