








/* eslint-disable max-len */

import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import Cta from '@/components/Cta.vue'
import FaqList from '@/components/FaqList.vue'
import Hero from '@/components/Hero.vue'

export default defineComponent({
  name: 'contact-page',
  components: {
    Cta,
    FaqList,
    Hero,
  },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
    }
  },
})
